.section-title {
  color: #ff4700;
  font-size: 40px;
  font-weight: 900;
}

.navLink {
  color: #2e2e2e;
}

.about {
  top: 20px;
  background-color: #ff4700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon_bg {
  background: #ff4700;
  margin: auto;
  border-radius: 100%;
  padding: 20px;
  width: 80px;
  height: 80px;
}

.icon_bg svg {
  color: #ffffff !important;
}

.icon_bg:hover {
  background: #ffded3;
  border: 1px solid #ff4700;
}

.icon_bg:hover svg {
  color: #ff4700 !important;
}

.cta {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}

.cta:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #ffd4c6;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ff4700;
}

.cta svg {
  position: relative;
  top: -1px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ff4700;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #ffd4c6;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

.offering {
  letter-spacing: 1.2px;
  color: #ff4700;
  font-size: 15px;
}

.section_title h2,
.border_bottom h5 {
  position: relative;
  padding-bottom: 15px;
}

.section_title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section_title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(50% - 20px);
}

.border_bottom h5::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
}

.border_bottom h5::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(13% - 20px);
}

.content ul {
  list-style: none;
  padding: 0;
}

.content ul li {
  padding: 0 0 10px 30px;
  position: relative;
}

.content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: 0;
  color: #ff4700;
}

.widthAdjust {
  width: 75%;
}

@media only screen and (max-width: 768px) {
  .border_bottom h5::after,
  .border_bottom h5::before {
    content: none;
  }
  .widthAdjust {
    width: 100%;
  }
}
