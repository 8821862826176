
.navbarbrand,
.navlink {
  color: white;
}

a {
  text-decoration: none !important;
}

.menuBtn {
  margin: 0 20px;
  display: none;
  color: rgb(0, 0, 0);
}

.login_btn {
  --color: #ff4700;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  margin-left: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-weight: 600;
  color: var(--color);
  background-color: #fff6f3;
  letter-spacing: 1.2px;
}

.login_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.login_btn:hover {
  color: #fff;
}

.login_btn:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.login_btn:hover:before {
  top: -30px;
  left: -30px;
}

.login_btn:active:before {
  background: #ff4700;
  transition: background 0s;
  -webkit-transition: background 0s;
  -moz-transition: background 0s;
  -ms-transition: background 0s;
  -o-transition: background 0s;
}

.fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.menu {
  margin: auto 0;
  display: flex;
  align-items: center;
  height: 75px;
}

.img_logo {
  width: 210px;
  padding: 5px 0;
}

.toggle,
[id^="drop"] {
  display: none;
}

nav {
  margin: 0;
  padding: 0 15px;
  background-color: #ffffff;
}

#logo {
  display: block;
  padding: 0 30px;
  float: left;
  font-size: 20px;
  line-height: 60px;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  margin: 0px;
  display: inline-block;
  background-color: #ffffff;
}

nav .main_menu a, nav .reach_us {
  display: block;
  padding: 26px 20px;
  color: #ff4700;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 1px;
  height: 78px;
}

nav ul li ul li:hover {
  background: #fff1eb;
}

nav .main_menu a:hover, nav .reach_us:hover {
  background-color: #fff6f3;
  color: #ff4700;
  border-bottom: 4px solid #ff4700;
}

nav li .submenu li:hover {
  background-color: #fff1eb !important;
}

nav li .submenu li a {
  padding: 18px 12px;
  color: #ff4700;
  letter-spacing: 1px;
}

nav li .submenu li:hover a {
  color: #e14000;
  letter-spacing: 1.2px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 77px;
  box-shadow: 0 1px 5px 0px #c7c7c7;
}

nav ul li:hover > ul {
  display: inherit;
}

nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
  padding: 15px 5px;
  border-bottom: 1px solid #edd6cd;
}

nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}

li > a:after {
  content: " +";
}
li > a:only-child:after {
  content: "";
}

/* Media Queries
--------------------------------------------- */

@media all and (max-width: 991px) {
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  .login_btn{
    margin: 15px;
  }

  nav .main_menu a, nav .reach_us {
    padding: 10px 20px;
    height: 43px;
    font-size: 14px;
  }

  nav .main_menu a:hover{
    border-bottom: 0;
  }

  nav {
    margin: 0;
    padding: 0;
  }

  .toggle + a, .toggle + .reach_us,
  .menu {
    display: none;
  }

  .toggle {
    display: block;
    background-color: #ffded1;
    padding: 8px 20px;
    color: #ff4700;
    font-size: 15px;
    text-decoration: none;
    border: none;
  }

  .toggle:hover {
    background-color: #ffebe3;
    color: #ff4700;
  }

  [id^="drop"]:checked + ul {
    display: block;
    width: 100%;
    height: 100%;
  }

  nav ul li {
    display: block;
    width: 100%;
  }

  nav ul ul li{
    padding: 0;
  }

  nav ul ul .toggle,
  nav ul ul a {
    padding: 0 40px;
  }

  nav ul ul ul a {
    padding: 0 80px;
  }

  nav a:hover,
  nav ul ul ul a {
    background-color: #ffebe3;
  }

  nav ul li ul li .toggle,
  nav ul ul a,
  nav ul ul ul a {
    padding: 14px 20px;
    color: #fff;
    font-size: 14px;
  }

  nav ul li ul li .toggle,
  nav ul ul a {
    background-color: #ffffff;
    color: #ff4700;
    display: block;
    padding: 10px 35px !important;
  }

  nav ul ul {
    float: none;
    position: static;
    color: #ffffff;
  }

  nav ul ul li:hover > ul,
  nav ul li:hover > ul {
    display: none;
  }

  nav ul ul li {
    display: block;
    width: 100%;
  }

  nav ul ul ul li {
    position: static;
  }
}

@media all and (max-width: 330px) {
  nav ul li {
    display: block;
    width: 94%;
  }
}
