.main_div {
  background-image: url("../../Assets/Images/building.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}

.bg_color {
  background-color: #561900;
  color: white;
  opacity: 0.8;
  z-index: 0;
  position: relative;
  height: 100%;
  align-items: center;
}

.card_color_bg {
  background-color: #ff4700;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 999;
}

.card_color_bg_reverse {
  background-color: #9f2c00;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 999;
}

.card_color_bg_reverse:hover{
    background-color: #6d1f00;
}

.card_color_bg:hover{
    background-color: #c53800;
}


.card_color_bg_reverse h6,
.card_color_bg h6 {
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
}

.card_color_bg_reverse img,
.card_color_bg img {
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .main_div {
    height: 100%;
  }
}