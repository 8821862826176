.counter_btns {
  background: transparent;
  position: relative;
  padding: 10px 15px;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  outline: none;
  overflow: hidden;
  color: rgb(255, 255, 255);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}
.fw600 {
  font-weight: 600;
}
.counter_btns span {
  margin: 10px;
}

.counter_btns:hover {
  color: #ff0000;
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 255, 255);
}

.count {
  font-size: 34px;
}

.cta svg {
  position: relative;
  top: -1px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ffffff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #ffd4c6;
}

.cta:hover svg {
  transform: translateX(0);
  stroke: #ff4700;
}

.cta:active {
  transform: scale(0.95);
}

.cta_change svg {
  position: relative;
  top: -1px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ff4700;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta_change:hover svg {
  transform: translateX(0);
  stroke: #ffffff;
}


.apply_now_btn {
  background: transparent;
  position: relative;
  padding: 10px 60px;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
  color: #ff4700;
  border: 1px solid #ff4700;
  background-color: #ffeee7;
}

.apply_now_btn:hover {
  color: #ffffff;
  border: 1px solid #ff4700;
  background-color: #ff4700;
}


@media all and (max-width: 767px) {
  .apply_now_btn {
    padding: 10px 40px;
  }
}