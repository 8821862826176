.bg_image {
  height: 95vh;
}


@media screen and (max-width: 1200px) {
  .bg_image {
    height: 100%;
  }
}
