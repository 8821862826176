.submit_btn {
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0.6em 2em;
  border: 2px solid #ff4700;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #ff4700;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
  background-color: white;
}

.submit_btn:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: #ff4700;
  z-index: -1;
}

.submit_btn:hover,
.submit_btn:focus {
  color: white;
}

.submit_btn:hover:before,
.submit_btn:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.submit_btn:active {
  transform: scale(0.9);
}
