.counter_btns {
  background: transparent;
  position: relative;
  padding: 10px 15px;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  outline: none;
  overflow: hidden;
  color: rgb(255, 255, 255);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}
.fw600
{
  font-weight: 600;
}
.counter_btns span {
  margin: 10px;
}

.counter_btns:hover {
  color: #ff0000;
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 255, 255);
}

.count {
  font-size: 34px;
}

.mainDiv {
  background-image: url("../../../Assets/Images/building.png");
  background-repeat: no-repeat;
  background-origin: center;
  background-size: cover;
  height: 40vh;
}

.bgColor {
  background-color: #ff4700;
  opacity: 0.8;
  z-index: 99;
  height: 100%;
  align-items: center;
}


.cta svg {
  position: relative;
  top: -1px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ffffff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #ffd4c6;
}

.cta:hover svg {
  transform: translateX(0);
  stroke: #ff4700;
}

.cta:active {
  transform: scale(0.95);
}

@media only screen and (max-width: 768px) {
  .mainDiv {
    height: 100%;
  }
}