.cards {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px 85px !important;
  background-color: #ff4700;
  color: white;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slide img{
  height: 80px;
  width: 250px;
}

.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;

  .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
    -webkit-animation: scroll 40s linear infinite;
  }
  .slide {
    margin: 20px 30px;
  }
}

.section_title h2 {
  position: relative;
  padding-bottom: 15px;
}

.section_title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 20px);
}

.section_title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(8% - 20px);
}

.section_title1 h2 {
  position: relative;
  padding-bottom: 15px;
}

.section_title1 h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section_title1 h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(50% - 20px);
}
.width_75, .about_img {
  width: 75%;
}

@media only screen and (max-width: 767px) {
  .width_75 {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  .text {
    text-align: center;
  }

  .slide img{
    height: 50px;
    width: 150px;
  }

  .cards {
    padding: 15px !important;
    text-align: center;
  }

  .about_img {
    width: 100%;
  }

  .section_title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section_title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #ff5722;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about_img {
    width: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  .cards {
    padding: 25px !important;
  }

  .width_75 {
    width: 100%;
  }
}
