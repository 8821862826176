.counter_btns {
  background: transparent;
  position: relative;
  padding: 10px 15px;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  outline: none;
  overflow: hidden;
  color: rgb(255, 255, 255);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

.counter_btns span {
  margin: 10px;
}

.counter_btns:hover {
  color: #ff0000;
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 255, 255);
}

.count{
    font-size: 48px;
}


.mainDiv{
    background-image: url('../../Assets/Images/building.png');
    background-repeat: no-repeat;
    background-origin: center;
    background-size: cover;
    height: 35vh;
}

.bgColor{
    background-color: #bf3400;
    opacity: 0.8;
    z-index: 99;
    height: 100%;
    align-items: center;
}

@media only screen and (max-width: 767px) {
  .mainDiv{
    height: 100%;
  }
}