.sliderWrapper {
  width: 100%;
  margin: 8px 0;
}
.sliderWrapper .sliderGroup {
  position: relative;
}
.sliderWrapper .sliderGroup .MuiInput-root {
  position: absolute;
  top: -35px;
  right: 0;
}
.sliderWrapper .sliderGroup .MuiInput-root:hover {
  border: none;
}
.sliderWrapper .sliderGroup .MuiInput-root:hover MuiInput-root:before {
  border: none;
}

.sliderWrapper .sliderGroup .MuiInput-root .MuiInput-input {
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #efefef;
}
.sliderWrapper .sliderGroup .MuiSlider-colorPrimary {
  margin-bottom: 36px;
}
.sliderWrapper .sliderGroup .MuiSlider-colorPrimary .MuiSlider-thumb {
  background: white;
  border: 1px solid;
}
.sliderWrapper .sliderGroup .MuiSlider-colorPrimary .MuiSlider-rail {
  background: white;
  border: 1px solid #ccc;
  height: 6px;
}
.sliderWrapper .sliderGroup .MuiSlider-colorPrimary .MuiSlider-mark {
  background: #fff;
}
.sliderWrapper .sliderGroup .MuiSlider-colorPrimary .MuiSlider-markLabel {
  color: #9f9f9f;
}

.sliderWrapper .sliderGroup .MuiSlider-colorPrimary .MuiSlider-markLabelActive {
  color: #9f9f9f;
  margin-left: 2%;
}

.sliderWrapper .sliderGroup .MuiSlider-root {
  color: #ff4700;
  height: 5px;
}
