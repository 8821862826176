.banner_text {
  padding-left: 5rem;
}

.bg_img {
  background-image: url("../../../Assets/Images/diverse-people-working-office.jpg");
  background-repeat: no-repeat;
  background-origin: center;
  background-size: cover;
  width: 100%;
  height: 75vh;
}

.bg_color {
  background-color: #b33200cf;
  opacity: 0.8;
  z-index: 99;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

@media only screen and (max-width: 480px) {
  .banner_text {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .bg_img {
    height: 50vh;
  }
}

@media only screen and (max-width: 1024px) {
  .bg_img {
    height: 50vh;
  }
}
