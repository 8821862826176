.criteria {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 2px 8px 0px #b7b7b7;
}

.criteria ul {
  list-style-type: none;
  padding-left: 0;
}

.criteria li {
  display: flex;
}

.criteria li:before {
  content: "\2713";
  display: inline-block;
  margin-right: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #ff4700;
}

.loan_criteria {
  line-height: 2;
}

.container_width {
  width: 75%;
}

.img_width1,
.img_width2 {
  width: 75%;
}

@media only screen and (max-width: 600px) {
  .criteria {
    padding: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .img_width1 {
    width: 100%;
    margin-top: 30px;
  }

  .img_width2 {
    width: 100%;
  }

  .container_width {
    width: 100%;
  }
}
