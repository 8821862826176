.contact {
  background-color: #f1f1f1;
}

.contact .info {
  border-top: 3px solid #ff4700;
  border-bottom: 3px solid #ff4700;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info svg {
  color: #ff4700;
  float: left;
  padding: 10px;
  width: 45px;
  height: 45px;
  background: #ffede6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h5 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #be3600;
}

.contact .info p {
  padding: 0 0 10px 65px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #f34400;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #ff4700;
  color: #fff;
}

.contact .info .email:hover svg,
.contact .info .address:hover svg,
.contact .info .phone:hover svg {
  background: #ff4700;
  color: #fff;
}

.contact .php_email_form {
  width: 100%;
  border-top: 3px solid #ff4700;
  border-bottom: 3px solid #ff4700;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php_email_form .form-group {
  padding-bottom: 8px;
}

.contact .php_email_form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php_email_form .error_message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php_email_form .error_message br + br {
  margin-top: 25px;
}

.contact .php_email_form .sent_message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php_email_form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php_email_form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php_email_form .form-group {
  margin-bottom: 20px;
}

.contact .php_email_form label {
  padding-bottom: 8px;
}

.contact .php_email_form input,
.contact .php_email_form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php_email_form input:focus,
.contact .php_email_form textarea:focus {
  border-color: #ff4700;
}

.contact .php_email_form input {
  height: 44px;
}

.contact .php_email_form textarea {
  padding: 10px 12px;
}

.contact .php_email_form button[type="submit"] {
  background: #ff4700;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php_email_form button[type="button"]:hover {
  background: #d53c00;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icon_bg {
  background: #ff4700;
  margin: auto;
  border-radius: 100%;
  padding: 20px;
  width: 80px;
  height: 80px;
}

.icon_bg svg {
  color: #ffffff !important;
}

.icon_bg:hover {
  background: #ffded3;
  border: 1px solid #ff4700;
}

.icon_bg:hover svg {
  color: #ff4700 !important;
}

.navLink {
  color: #3d3d3d;
}

.section_title h2 {
  position: relative;
  padding-bottom: 15px;
}

.section_title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section_title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(50% - 20px);
}

@media all and (max-width: 767px) {
  .icon_bg {
    padding: 15px;
    width: 60px;
    height: 60px;
  }
}
