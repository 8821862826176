.criteria {
  width: 100%;
  margin: 0 auto;
}

.criteria ul {
  list-style-type: none;
  padding-left: 0;
}

.criteria li {
  display: flex;
}

.criteria li:before {
  content: "\2713";
  display: inline-block;
  margin-right: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #ff4700;
}

.loan_criteria {
  line-height: 2;
}

.nav_shadow {
  box-shadow: 0 2px 15px rgb(0 0 0 / 5%) !important;
}
.nav_border {
  border-right: 3px solid #ff4700;
}
.nav_pills .nav_link {
  padding: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  color: #333;
  transition: all 0.3s;

}

.nav_link .circle_icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 2px solid #ff4700;
  background: #ff4700;
  color: #ffffff;
  font-size: 22px;
}
.img_cont {
  width: 20%;
}

.section_title h2{
  position: relative;
  padding-bottom: 15px;
}

.section_title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section_title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(50% - 20px);
}

@media screen and (max-width: 991px) {

}