.sidenav div {
  left: -160px;
  transition: 0.3s;
  padding: 10px;
  font-size: 14px;
  color: white;
  border-radius: 0 5px 5px 0;
  position: fixed;
  z-index: 1;
  width: 200px;
  text-align: center;
  margin-top: 25rem;
}

.sidenav p:hover {
  left: 0;
}

.about {
  top: 20px;
  background-color: #ff4700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container_top {
  position: fixed;
  right: 2%;
  bottom: 10%;
  display: flex;
  z-index: 9999;
}

.top {
  position: sticky;
  bottom: 20px;
  width: 45px;
  aspect-ratio: 1;
  background: #ff4700;
  border-radius: 10px;
}
.top svg {
  position: absolute;
  animation: jump 1s infinite;
  color: white;
  font-size: 38px;
  width: 100%;
  -webkit-animation: jump 1.2s infinite;
}

.top:hover {
  background: #ffffff;
}

.top:hover svg {
  color: #ff4700;
}

@keyframes jump {
  0% {
    top: 0;
  }
  50% {
    top: 5px;
  }
  100% {
    top: 0;
  }
}
