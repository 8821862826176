.app-wrapper {
  text-align: left;
  background-color: #f9f9f9;
}
.app-wrapper .app-container {
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 120px;
}

.section_title h2{
  position: relative;
  padding-bottom: 15px;
}

.section_title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section_title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(50% - 20px);
}

.widthAdjust{
  width: 50%;
}

@media screen and (max-width: 10240px) {
  .widthAdjust{
    width: 75%;
  }
}

@media screen and (max-width: 600px) {
  .app-wrapper {
    width: 100%;
  }
  .app-wrapper .app-container {
    width: 90%;
    padding: 24px 0;
    margin: 0 auto;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  .widthAdjust{
    width: 100%;
  }
}
