.bg-dark {
    background-color: #1c1c26;
    color: white;
}


.footer {
    color: #B0B9AE;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #B0B9AE;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link:hover {
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    box-shadow: none;
}

.bottom_footer{
    background-color: #16161f;
    color: rgb(181, 181, 181);
    font-size: 14px;
    padding: 25px 0;
}