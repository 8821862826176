.text_orange {
  color: #ff4700 !important;
}
.bg-gray {
  background-color: rgb(241, 241, 241);
}

.carousel-caption {
  left: 13% !important;
  color: #ff4700 !important;
  top: 25% !important;
  text-align: left !important;
}

.carousel-inner {
  /* height: 90vh; */
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.nav-pills .nav-link.active {
  background: linear-gradient(120deg, #ff4700, #f7b733) !important;
  color: #fff !important;
  box-shadow: 0 2px 15px rgb(0 0 0 / 10%) !important;
  border-radius: 0px;
  font-weight: bold;
}

.nav-pills .nav-link.active .circle_icon_insurence {
  border: 2px solid #ffffff;
  background: #ffffff !important;
  color: #ff4700;
}

.nav-pills .nav-link:hover {
  color: #ff4700;
}

.carousel-indicators [data-bs-target] {
  background-color: #ff4700 !important;
}

.carousel-indicators {
  margin-bottom: 10px !important;
}

.paddingTopHeader {
  padding-top: 75px;
}

@media screen and (max-width: 1200px) {
  .carousel {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .paddingTopHeader {
    padding-top: 115px;
  }
}
