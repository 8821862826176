.section_title h2 {
  position: relative;
  padding-bottom: 15px;
}

.section_title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section_title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ff5722;
  bottom: 0;
  left: calc(50% - 20px);
}

.offering {
  letter-spacing: 1.2px;
  color: #ff4700;
  font-size: 15px;
}

.loan_points li {
  line-height: 2.2;
}

.faq .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.faq .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.faq .content p {
  font-size: 15px;
  color: #6c757d;
}

.faq .accordion_item {
  border: 0;
  margin-bottom: 20px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.093);
  border-radius: 10px;
}

.accordion_body {
  padding: 5px 15px;
}

.faq .accordion_button {
  padding: 25px 50px 25px 20px;
  font-weight: 600;
  width: 100%;
  border: 0;
  font-size: 18px;
  line-height: 24px;
  color: #353535;
  text-align: left;
  background: #fff9f6;
  box-shadow: none;
  border: 1px solid #ffd6c5;
  border-bottom: none;
  border-radius: 10px;
}

.about h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
}

.about .contant_data ul {
  list-style: none;
  padding: 0;
}

.about .contant_data ul li {
  padding: 0 0 10px 30px;
  position: relative;
}

.about .contant_data ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--color-primary);
}

.about .contant_data p:last-child {
  margin-bottom: 0;
}


@media all and (max-width: 767px) {
  .faq .accordion_button {
    padding: 15px 10px 15px 10px;
    font-size: 16px;
  }
}