.section_our_solution .row {
  align-items: center;
}

.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solution_cards_box .solution_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 250px;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover {
  background: #ff4700;
  color: #fff;
  transform: scale(1.05);
  z-index: 9;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.solution_cards_box .solution_card:hover::before {
  background: #9962352e;
}

.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
  color: #fff;
}

.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: #ff470014;
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.solution_card .so_top_icon {
}

.solution_card .solu_title div {
  color: #212121;
  font-size: 1.2rem;
  margin-top: 13px;
  margin-bottom: 13px;
  font-weight: 600;
}

.solution_card:hover .solu_title div {
  color: #fafafa;
  font-size: 1.3rem;
}

.solution_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

/* .solution_card .solu_description button {
  border: 0;
  border-radius: 4px;
  background: linear-gradient(
    140deg,
    #ff4700 0%,
    #ff4700 50%,
    #f37f20ce 75%
  ) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
} */

.our_solution_content div {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.our_solution_content p {
}

.hover_color_bubble {
  position: absolute;
  background: #ff470036;
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}

.cta {
  position: relative;
  text-align: left;
  padding: 12px 0px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}

.cta span {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ff4700;
}

.solution_card:hover .solu_description span,
.solution_card:hover .solu_description svg {
  color: #ffffff;
  stroke: #ffffff;
}

.cta svg {
  position: relative;
  top: -1px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ff4700;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #ffd4c6;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

.desc_grp {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 50%;
}

/*start media query*/
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_solution_category .solution_cards_box {
    flex: auto;
  }
}

@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }
}
