.chartfooter-wrapper {
    padding: 24px 0;
    display: grid;
    text-align: left;
    grid-template-columns: repeat(3, 1fr);
}
.chartfooter-wrapper .chartfooterIcon-container {
    display: flex;
    align-items: center;
}
.chartfooter-wrapper .chartfooterIcon-container .chartfooter-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
}
.chartfooter-wrapper .chartfooterIcon-container .chartfooter-icon__green {
    background: rgba(41, 157, 47, 1);
}
.chartfooter-wrapper .chartfooterIcon-container .chartfooter-icon__yellow {
    background: rgba(255, 165, 0, 1);
}
.chartfooter-wrapper .chartfooterIcon-container .chartfooter-icon__blue {
    background: rgba(124, 181, 236, 1);
}
